@import "variables";

@import "addons/font-icons";
@import "addons/font-icons-reset";

@import "~bootstrap-select/dist/css/bootstrap-select.min.css";
@import "addons/bootstrap-select-reset";

@import "addons/lightbox-reset";

.img-responsive-16by9 {
    display: block;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
}

.carousel-item {
    img {
        height: 450px;
    }
}

@import "theme/header";
@import "theme/footer";

@import "folds/list-properties";
@import "folds/optin";

@import "pages/home";
@import "pages/contact";
@import "pages/property";
@import "pages/filter";
@import "pages/register";

body {
    font-family: "Open Sans", sans-serif;
}
