.main_header {
  .header_bar {
    p {
      font-size: 0.875em;
    }

    i {
      font-size: 1.6em;
    }
  }
}