.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
    outline: none !important;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: $color-front-default;
}

.bootstrap-select .dropdown-menu li a {
    outline: none;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
    color: #000;
}

.form_home .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    color: #000;
    padding: 0px 2px;
    font-weight: 600;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    border: 2px solid #0071ce;
    border-radius: 4px;
}

.dropdown-toggle::after {
    border-top-color: #000;
}

.dropup .dropdown-toggle::after {
    border-bottom-color: #000;
}
