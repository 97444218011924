.realty_list {
    width: 100%;
    flex-basis: 100%;

    .realty_list_item_actions_stats {
        position: relative;
        padding: 0;

        ul {
            display: block;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
            font-size: 0.75rem;
            margin: 0;
            padding: 0;

            li {
                padding: 10px;
                list-style: none;
            }
        }
    }
}

.page_filter {
    .bootstrap-select .btn {
        background-color: #fff;
        color: #222;
        font-weight: 400;
        font-size: 0.75rem;

        &:hover {
            color: #111 !important;
        }
    }
}

.slider-custom {
    input {
        font-size: 0.75rem;
        color: #222;
        font-weight: 400;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 100%;
        display: flex;
        align-items: center;
        height: 42px;
    }

    .ui-draggable,
    .ui-droppable {
        background-position: top;
    }
}

#filter-price {
    display: block;
    height: 34px;
    padding: 5px 10px;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.4;
    color: #444;
    background: transparent;
    border: 1px solid #ccc;
    border-radius: 5px;
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-sizing: border-box;
    margin-top: -5px;
}

.automotive_cover_container {
    position: relative;

    .filter_car_img {
        height: 200px;
        object-fit: cover;

        @media (max-width: 991px) {
            height: 100%;
        }
    }

    .heart-like {
        color: #0f0f0f;
        position: absolute;
        background-color: rgba(245, 237, 237, 0.75);
        padding: 5px;
        border-radius: 50%;
        margin: 10px !important;
        line-height: unset !important;

        &:before {
            margin: 0 0.25rem !important;
        }
    }
}

.automotive_properties {
    border: 2px solid #0f0f0f;
}
