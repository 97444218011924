.main_slide {
  .main_slide_content {
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.7);
    text-transform: uppercase;
    font-size: 2.5em;
    font-weight: 100;
    border-radius: 15px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
  }
}

.main_list_group {
  .main_list_group_item {
    .main_list_group_items_item {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      a {
        flex-basis: 100%;
        min-height: 200px;
        color: #fff;
        transition: 0.2s;
        font-size: 1.2em;
        text-shadow: 0px 3px 5px #333;

        &:hover {
          text-decoration: none;
          font-size: 1.3em;
        }

        div {
          flex-basis: 100%;
          min-height: 200px;

          h2 {
            color: #fff;
            font-size: 1.2em;
            padding: 10px;
            width: 100%;
            text-align: center;
            background-color: rgba(0, 0, 0, 0.7);
          }
        }
      }
    }
  }
}
