.main_properties {
  .main_properties_item {
    &:hover {
      transform: scale(1.05);
      -o-transform: scale(1.05);
      -webkit-transform: scale(1.05);
      -moz-transform: scale(1.05);
      transition-duration: 0.5s;
      box-shadow: 0 0 7px #666;

      .main_properties_complement {
        margin-bottom: 0 !important;
      }
    }

    h2 {
      font-size: 0.9rem;
      margin-top: 10px;
      font-weight: 500;
      text-transform: uppercase;
      font-family: Arial, Helvetica, sans-serif;

      a:hover {
        text-decoration: none;
      }
    }

    .main_properties_item_category {
      margin-bottom: 0px;
    }

    .main_properties_item_type {
      font-size: 0.875em;
      color: #666;
    }

    .main_automotives_item {
      font-size: 0.85em;
      font-family: Arial, Helvetica, sans-serif;
    }

    .main_properties_price {
      font-size: 1.4em;
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
    }

    .main_properties_complement {
      display: flex;
      justify-content: space-between;
      font-size: 0.75rem;
      color: #bbb;
      background-color: transparent;
      margin: 0 -10px -3px -10px;
      padding-right: 10px;
      padding-left: 10px;
    }

    .main_properties_features {
      img {
        max-width: 40px;
      }

      p {
        margin-top: 6px;
        font-size: 0.875em;
        margin-bottom: 0px;
      }
    }
  }
}
